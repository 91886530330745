import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const CategoryComponent = ({ categories, onSelectCategory }) => {
    return (
        <Grid container spacing={2} sx={{ padding: '25px' }}>
            {categories.map((category) => (
                <Grid item key={category.id} xs={4} style={{ maxWidth: '33.333333%', flexBasis: '33.333333%' }}>
                    <Card onClick={() => onSelectCategory(category.id)} style={{ cursor: 'pointer', border: '3px solid #d5a9a0', minHeight: '140px', display:'flex', alignItems:'center', justifyContent: 'center' }}>
                        <CardContent style={{ userSelect: 'none' }}>
                            <Typography variant="h6" style={{ fontWeight: 600, textAlign: 'center', fontSize: '1.2rem',}}>
                                {category.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default CategoryComponent;
