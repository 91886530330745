import React from 'react';
import { Button } from '@mui/material';

const FilterComponent = ({ filters, selectedFilter, onSelectFilter }) => {
    const handleSelectFilter = (filterId) => {
        const newFilter = filterId === selectedFilter ? null : filterId;
        onSelectFilter(newFilter);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {filters.map((filter) => {
                let borderStyle = 'transparent';

                if (filter.id === '1' && selectedFilter === null) {
                    borderStyle = 'black';
                } else if (filter.id === selectedFilter) {
                    borderStyle = 'black';
                }

                return (
                    <Button
                        key={filter.id}
                        onClick={() => handleSelectFilter(filter.id)}
                        variant="contained"
                        style={{
                            fontWeight: filter.id === selectedFilter ? 'bold' : 'normal',
                            margin: '8px', 
                            backgroundColor: '#d5a9a0', 
                            padding: '10px 20px', 
                            fontSize: '1rem',
                            border: `2px solid ${borderStyle}`, 
                            '@media (max-width: 600px)': {
                                fontSize: '0.8rem',
                                padding: '8px 16px',
                            },
                        }}
                    >
                        {filter.name}
                    </Button>
                );
            })}
        </div>
    );
};

export default FilterComponent;
