import React, { useState, useRef } from 'react';
import { Paper, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Button, Stack, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import emptyCartImage from './carrinho_vazio.png';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CartComponent = ({ cart, total, setCart, setTotal, elevation }) => {
    const [showPromotions, setShowPromotions] = useState(false);
    const [showingPromotionTable, setShowingPromotionTable] = useState(false);
    const isEmptyCart = cart.length === 0;
    const paperRef = useRef(null);

    const handleIncrement = (itemId) => {
        const updatedCart = cart.map((item) => {
            if (item.id === itemId) {
                const newQuantity = item.quantity + 1;
                const priceDifference = item.product.price10x * (newQuantity - item.quantity);
                setTotal(total + priceDifference);
                return { ...item, quantity: newQuantity };
            }
            return item;
        });
        setCart(updatedCart);
    };

    const handleDecrement = (itemId) => {
        const updatedCart = cart.map((item) => {
            if (item.id === itemId) {
                const newQuantity = item.quantity - 1;
                if (newQuantity <= 0) {
                    handleRemoveItem(itemId);
                    return null;
                } else {
                    const priceDifference = item.product.price10x * (newQuantity - item.quantity);
                    setTotal(total + priceDifference);
                    return { ...item, quantity: newQuantity };
                }
            }
            return item;
        }).filter(Boolean);
        setCart(updatedCart);
    };

    const handleRemoveItem = (itemId) => {
        const itemToRemove = cart.find((item) => item.id === itemId);
        if (itemToRemove) {
            const priceDifference = itemToRemove.product.price10x * itemToRemove.quantity;
            setTotal(total - priceDifference);
            const updatedCart = cart.filter((item) => item.id !== itemId);
            setCart(updatedCart);

            if (cart.length === 1) {
                setShowPromotions(false)
                setShowingPromotionTable(false)
            }
        }
    };

    const handlePromotionButtonClick = () => {
        setShowingPromotionTable(!showingPromotionTable);
    };

    const handleRemoveAllItems = () => {
        setCart([]);
        setTotal(0);
        setShowPromotions(false)
        setShowingPromotionTable(false)
    };


    const paymentMethods = () => {
        const totalPixDebito = cart.reduce((accumulator, item) => {
            return accumulator + item.product.price_pix * item.quantity;
        }, 0);

        const total6x = cart.reduce((accumulator, item) => {
            return accumulator + item.product.price6x * item.quantity;
        }, 0);

        const total10x = cart.reduce((accumulator, item) => {
            return accumulator + item.product.price10x * item.quantity;
        }, 0);

        return { totalPixDebito, total6x, total10x };
    };

    const { totalPixDebito, total6x, total10x } = paymentMethods();

    const percentualDescontoPix = Math.round(((total - totalPixDebito) / total) * 100);
    const percentualDesconto6x = Math.round(((total - total6x) / total) * 100);
    const percentualDesconto10x = Math.round(((total - total10x) / total) * 100);

    return (
        <Paper
            ref={paperRef}
            elevation={elevation}
            style={{
                padding: '20px',
                backgroundColor: '#FFFFFF',
                color: '#333',
                height: '100%',
                position: 'relative',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', flexDirection: 'column' }}>
                <Typography variant="h3" style={{ fontWeight: 600, fontSize: '1.8rem', marginRight: '8px' }}>
                    Orçamento
                </Typography>
                {!isEmptyCart ? (
                    <>
                        <IconButton
                            style={{ opacity: 0.1, color: 'black', cursor: 'default', position: 'absolute', top: '.8rem', left: '10.5rem' }}
                            aria-label="carrinho"
                            onClick={handlePromotionButtonClick}
                        >
                            <ShoppingCartIcon />
                        </IconButton>
                        <Button
                            variant="text"
                            style={{ position: 'absolute', top: '0.5rem', right: '1rem', border: '1px solid #999', color: '#999' }}
                            onClick={handleRemoveAllItems}
                            aria-label="remover todos os itens"
                        >
                            Remover tudo
                        </Button>
                    </>
                ) : (
                    <div style={{ margin: '0 auto' }}>
                        <img src={emptyCartImage} alt="Carrinho Vazio" style={{ width: '400px', height: 'auto' }} />
                        <Typography>Carrinho vazio, que tal adicionar alguns itens?</Typography>
                    </div>
                )}


            </div>

            {!isEmptyCart && !showingPromotionTable && (
                <TableContainer component={Paper} elevation={0} style={{ marginBottom: '24px', backgroundColor: 'transparent', overflowX: 'hidden' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ borderBottom: '2px solid #ccc' }}>
                                <TableCell style={{ textAlign: 'center' }}>Código</TableCell>
                                <TableCell style={{ textAlign: 'center', width: '30%' }}>Produto</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Qtd</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Valor</TableCell>
                                <TableCell style={{ textAlign: 'center' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.product.code}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.product.name}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="remove"
                                            onClick={() => handleDecrement(item.id)}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        {item.quantity}
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="add"
                                            onClick={() => handleIncrement(item.id)}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>R$ {(item.product.price10x * item.quantity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        <IconButton
                                            size="small"
                                            color="red"
                                            aria-label="remove"
                                            onClick={() => handleRemoveItem(item.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {!isEmptyCart && showingPromotionTable && (
                <TableContainer component={Paper} elevation={0} style={{ backgroundColor: 'transparent', overflowX: 'hidden' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ borderBottom: '2px solid #ccc' }}>
                                <TableCell style={{ textAlign: 'center' }}>Código</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Produto</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Quantidade</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Pix</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>6x</TableCell>
                                <TableCell style={{ textAlign: 'center' }}>10x</TableCell>
                                <TableCell style={{ textAlign: 'center' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.product.code}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.product.name}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="remove"
                                            onClick={() => handleDecrement(item.id)}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        {item.quantity}
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="add"
                                            onClick={() => handleIncrement(item.id)}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>R$ {(item.product.price_pix * item.quantity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>R$ {(item.product.price6x * item.quantity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>R$ {(item.product.price10x * item.quantity).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        <IconButton
                                            size="small"
                                            color="red"
                                            aria-label="remove"
                                            onClick={() => handleRemoveItem(item.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}



            {!isEmptyCart && (
                <div style={{ backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '8px 20px', marginTop: 'auto' }}>
                    <Typography
                        variant="h6" className='total_verde'
                        style={{
                            fontWeight: 300,
                            fontSize: '1.2rem',
                            marginBottom: '8px',
                            background: '#8cc4c5',
                            padding: '1rem 1rem',
                            borderRadius: '.8rem',
                            color: '#fff',
                            userSelect: 'none',
                            position: 'relative',
                            paddingLeft: '1.5rem'
                        }}
                    >
                        <IconButton
                            onClick={() => setShowPromotions(!showPromotions)}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '1rem',
                                transform: 'translateY(-50%)',
                                // backgroundColor: '#333',
                                borderRadius: '50%',
                                padding: '8px'
                            }}
                        >
                            <AttachMoneyIcon style={{ color: '#fff' }} />
                        </IconButton>
                        Total: R$ {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                    </Typography>


                </div>
            )}

            {!isEmptyCart && showPromotions && (
                <TableContainer component={Paper} elevation={0} style={{ backgroundColor: 'transparent', overflowX: 'hidden', border: 'solid 4px #d5a9a0', borderRadius: '1rem', marginTop: '1rem' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ borderBottom: '2px solid #ccc' }}>
                                <TableCell style={{ textAlign: 'center', fontSize: '20px' }}>Forma</TableCell>
                                <TableCell style={{ textAlign: 'center', fontSize: '20px' }}>Parcela</TableCell>
                                <TableCell style={{ textAlign: 'center', fontSize: '20px' }}>Total</TableCell>
                                <TableCell style={{ textAlign: 'center', fontSize: '20px' }}>Desconto (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>Pix</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {(totalPixDebito / 1).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {totalPixDebito.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>
                                    {!percentualDescontoPix ? `-` : `${percentualDescontoPix}%`}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>6x</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {(total6x / 6).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {total6x.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>                                    {!percentualDesconto6x ? `-` : `${percentualDesconto6x}%`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>10x</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {(total10x / 10).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>R$ {total10x.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                <TableCell style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '20px' }}>                                    {isNaN(percentualDesconto10x) ? `-` : `${percentualDesconto10x}%`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {((total10x / 10) < 100 || (total6x / 6) < 100) && (
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">
                                AVISO: As parcelas em 10x e/ou 6x são menores que R$100,00.
                            </Alert>
                        </Stack>
                    )}
                </TableContainer>
            )}

        </Paper>
    );
};

export default CartComponent;