import React, { useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image'

const AddProductForm = ({
  newProductFormData,
  handleNewProductChange,
  handleAddProductFormSubmit,
  setShowAddForm,
  categories,
  filters,
  imageSetOpenDialog,

}) => {
  const [selectedFilter, setSelectedFilter] = useState('');


  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setSelectedFilter(selectedFilter);
    handleNewProductChange(e);
  };

  const formatWithTwoDecimals = (value) => {
    const strValue = value.toString().replace('.', '');
    const length = strValue.length;
    const formattedValue = `${strValue.slice(0, length - 2)}.${strValue.slice(length - 2)}`;
    return parseFloat(formattedValue).toFixed(2);
  };

  const handleNumberChange = (e) => {
    const formattedValue = formatWithTwoDecimals(e.target.value);
    handleNewProductChange({ target: { name: e.target.name, value: formattedValue } });
  };

  const handleIntegerChange = (e) => {
    handleNewProductChange({ target: { name: e.target.name, value: e.target.value } });
  };

  const filteredCategories = categories.filter(cat => String(cat.filter) === String(selectedFilter));

  return (
    <form onSubmit={handleAddProductFormSubmit} className="add-product-form" style={{ width: '100%' }}>
      <input
        type="text"
        name="name"
        value={newProductFormData.name}
        onChange={handleNewProductChange}
        placeholder="Nome do Produto"
        required
        className="add-product-input"
      />

      <select
        name="filter"
        value={selectedFilter}
        onChange={handleFilterChange}
        required
        className="add-product-select"
      >
        <option value="">Selecione um Filtro</option>
        {filters.map((filter) => (
          <option key={filter.id} value={filter.id}>
            {filter.name}
          </option>
        ))}
      </select>

      {selectedFilter && (
        <select
          name="category"
          value={newProductFormData.category}
          onChange={handleNewProductChange}
          required
          className="add-product-select"
        >
          <option value="">Selecione uma Categoria</option>
          {filteredCategories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      )}
      <TextField
        autoFocus
        name='code'
        margin="dense"
        label="Código"
        type="text"
        fullWidth
        value={newProductFormData.code}
        onChange={handleNewProductChange}
      />
      <input
        type="number"
        name="order"
        value={newProductFormData.order}
        onChange={handleIntegerChange}
        placeholder="Ordem"
        required
        className="add-product-input"
      />
      <input
        type="number"
        name="price10x"
        value={newProductFormData.price10x}
        onChange={handleNumberChange}
        placeholder="Preço 10x"
        required
        className="add-product-input"
      />
      <input
        type="number"
        name="price6x"
        value={newProductFormData.price6x}
        onChange={handleNumberChange}
        placeholder="Créd/Déb"
        required
        className="add-product-input"
      />
      <input
        type="number"
        name="price_pix"
        value={newProductFormData.price_pix}
        onChange={handleNumberChange}
        placeholder="Preço PIX"
        required
        className="add-product-input"
      />
      {/* <input
        type="number"
        name="desconto_maximo"
        value={newProductFormData.desconto_maximo}
        onChange={handleNumberChange}
        placeholder="Desconto Máximo"
        required
        className="add-product-input"
      /> */}
      {/* <input
        type="number"
        name="valor_maximo"
        value={newProductFormData.valor_maximo}
        onChange={handleNumberChange}
        placeholder="Valor Máximo"
        required
        className="add-product-input"
      /> */}

      {/* <div style={{ display: 'flex' }}>
        <input
          type="text"
          name="image_path"
          value={newProductFormData.image_path.replace('img/', '')}
          onChange={handleNewProductChange}
          placeholder="Caminho da Imagem (nome)"
          className="add-product-input"
          style={{ width: '100%' }}
        />
        <IconButton onClick={() => imageSetOpenDialog(true)}>
          <ImageIcon style={{ fontSize: '100%', height: '100%', fontSize: 'larger' }} />
        </IconButton>
      </div> */}
      {/* <div className="add-product-buttons">
        <button type="submit" className="add-product-submit">Adicionar Produto</button>
      </div> */}
    </form>
  );
};

export default AddProductForm;
