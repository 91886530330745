import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const ProductComponent = ({ products, onSelectProduct }) => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    useEffect(() => {
        const handleOrientationChange = () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    return (
        <Grid container spacing={2} sx={{ padding: '0 25px 25px 25px' }}>
            {products.map((product) => (
                <Grid
                    item
                    key={product.id}
                    xs={isPortrait ? 4 : 6}
                    sm={isPortrait ? 6 : 12}
                    style={{ maxWidth: isPortrait ? '33.333333%' : '50%', flexBasis: isPortrait ? '33.333333%' : '50%' }}
                >
                    <Card
                        onClick={() => onSelectProduct(product)}
                        style={{
                            cursor: 'pointer',
                            border: '3px solid #d5a9a0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        {product.is_promotional && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '25px',
                                    left: '5px',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                <LocalOfferIcon sx={{ fontSize: '25px' }} />
                            </Box>
                        )}
                        {product.is_trending && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                    transform: 'translateY(-50%)',
                                    color: '#E8CA37',
                                    fontSize: '1rem'
                                }}
                            >
                                <StarOutlineIcon sx={{ fontSize: '25px' }} />
                            </Box>
                        )}
                        <CardContent
                            style={{
                                userSelect: 'none',
                                minHeight: '120px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6" style={{ fontSize: 'clamp(14px, 2vw, 20px)' }}>
                                {product.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: 'clamp(16px, 2vw, 20px)' }}
                            >
                                R${parseFloat(product.price10x).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default ProductComponent;
