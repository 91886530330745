import React, { useState } from 'react';
import {
    IconButton,
    Popover,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Avatar,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const UserPopup = ({ user, token }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [username, setUsername] = useState(user.username);
    const [password, setPassword] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
        handleClose(); // Fecha o popup quando o diálogo abrir
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleUpdate = async () => {
        try {
            const response = await fetch('https://plenaestetic.com.br/api/user/update/?token=85la3489da5328154913543fm', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Erro da API:', errorData);
                throw new Error('Erro ao atualizar usuário.');
            }
    
            const data = await response.json();
            console.log('Usuário atualizado com sucesso:', data);
    
            // Sugira ao usuário que faça logout e login novamente
            alert('Senha atualizada com sucesso! Por favor, faça logout e login novamente.');
            
        } catch (error) {
            console.error('Erro:', error);
        } finally {
            handleDialogClose();
        }
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'user-popover' : undefined;

    return (
        <div>
            <Button onClick={handleClick} size="large">
                <AccountCircleIcon style={{ background: "white", color: '#8cc4c5', borderRadius: '50%' }} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: '16px', textAlign: 'center' }}>
                    <Typography variant="h6">Informações do Usuário</Typography>
                    <AccountCircleIcon style={{ background: "white", color: '#8cc4c5', borderRadius: '50%', width: "70px", height: "70px" }} />
                    <Typography variant="body1" gutterBottom>
                        Usuário: {username}
                    </Typography>
                    <Button variant="contained" style={{ background: '#8cc4c5' }} onClick={handleDialogOpen}>
                        Editar Usuário/Senha
                    </Button>
                </div>
            </Popover>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Editar Usuário e Senha</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Novo Nome de Usuário"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Nova Senha"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpdate} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserPopup;
